export type BrowserInfo = {
  screenWidth: string | number
  screenHeight: string | number
  colorDepth: string | number
  userAgent: string
  timeZoneOffset: number
  language: string
  javaEnabled: boolean
}

export const getBrowserInfo = (): BrowserInfo => ({
  screenWidth: window?.screen?.width ?? '',
  screenHeight: window.screen.height ?? '',
  colorDepth: window?.screen?.colorDepth ?? '',
  userAgent: window?.navigator?.userAgent ?? '',
  timeZoneOffset: new Date().getTimezoneOffset(),
  language: window?.navigator?.language ?? '',
  javaEnabled: window?.navigator?.javaEnabled() || false,
})
